import glasses from './glasses.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import { useState, useCallback } from 'react';
import SlowType from './SlowType.jsx';
import { genUrl, parseUrl, getUserId } from './utils/util';

function App() {
  // const responseText= "test"
  // const setResponseText = ()=>{}
  const [responseText, setResponseText] = useState("Oh, you found me! No doubt thanks to that message the father left...");
  const [statement, setStatement] = useState("");
  const urlObject = parseUrl(window.location);

  if(!urlObject) {
    window.location.replace(genUrl(window.location))
  }

  const steamId = getUserId(urlObject);
  console.log(getUserId(urlObject));

  const getChatResponse = useCallback(async (statement) => {

    async function sendMessage(statement) {
      try {
        const res = await fetch(`https://secretchat-api-wwf62j34fa-uc.a.run.app/chat?statement=${statement}&steamId=${steamId}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': 'en-US',
          },
        });
        return res;
      } catch (e) {
        console.log(e);
      }
    }
    const res = await sendMessage(statement).then(response=>response.json())
    .then(data=>{ 
      setResponseText(data);
    });

  }, [responseText]);

  return (
    <div className="App">
      <img src={glasses} className="Glasses" alt="glasses" />


      <p className="Response">
        <SlowType key={responseText} text={responseText}/>
      </p>
      
      <div className="Chat">
        <input className="Message-Input" name="myInput" onInput={e => setStatement(e.target.value)}/>
        <Button className="Send-Message" onClick={()=>{getChatResponse(statement)}}>Send</Button>
      </div>
    </div>
  );
}

export default App;
