export const http_build_query = (obj) => {
    let str = ""

    for (const key in obj) {
        const value = obj[key];
        str += `${key}=${value}&`;
    }

    return str;
}

export const STEAM_LOGIN="https://steamcommunity.com/openid/login"

export const genUrl = (urlToReturnTo) => {
    const params = {
        'openid.ns'         : 'http://specs.openid.net/auth/2.0',
        'openid.mode'       : 'checkid_setup',
        'openid.return_to'  :  urlToReturnTo,
        'openid.realm'      :  urlToReturnTo,
        'openid.identity'   : 'http://specs.openid.net/auth/2.0/identifier_select',
        'openid.claimed_id' : 'http://specs.openid.net/auth/2.0/identifier_select',
    };

    const url = `${STEAM_LOGIN}?${http_build_query(params)}`
    return url;
};

export const getUserId = (response) =>
{
    const str = response["openid.claimed_id"];
    const res = decodeURIComponent(str)
    const propsArr = res.split("\/");
    console.log(propsArr);

    return propsArr[propsArr.length-1];
}

export const parseUrl = (url) => {
    const search = url.search.substring(1);
    try{
        return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
    } catch( e ) { 
        return null
    }
}