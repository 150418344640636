import { useState, useEffect } from "react";

const SlowType = ({text}) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const delay = 100;

  useEffect(() => {
    let timeout;
    
    let newDelay = delay
    if ("!.?,".includes(text[currentIndex-1])){
      newDelay = delay * 5;
    }
    
    if (currentIndex <= text.length -1) {
      timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, newDelay);
    }

    return () => clearTimeout(timeout);
  }, [currentIndex, delay, text]);

  return <span>{currentText}</span>;
};

export default SlowType;